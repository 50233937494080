.ContainerRowOverlap {
    display: grid;
    min-height: 100%;
    height: 100%;
  }
  .layer {
    grid-column: 1;
    grid-row: 1;
  }
  /*.col {
      display: flex;
      justify-content: center;
      height: 400px;  or other desired height 
      width: 100px;
      overflow: hidden;
  }
  */
  .BrowseOverlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10000;
    background-color: rgb(255, 255, 255);
  }
  .BrowsePopup {
    height: 80%;
    width: 80%;
    border-radius: 15px;
    max-width: 1300px;
    box-sizing: border-box;
    padding: 15px 40px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
    position: relative;
  }
  .ChooseUniversityTitle {
    font-size: 3rem;
    margin-bottom: 10vh;
  }
  .imagesBWHover {
    margin: 30px 20px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    transition-duration: 200ms;
  }
  .imagesBWHover:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }
  .Universities {
    text-align: center;
    font-size: 18px;
    margin: auto;
    padding: 20px;
    line-height: 1.5;
    max-width: 1000px;
    color: #1a3257;
    position: relative;
  }
  .InputContainer {
    position: relative;
    height: auto;
    font-size: 16px;
    z-index: 1;
  }
  .Input {
    font-size: inherit;
    display: block;
    border-radius: 6px;
    outline: none;
    border: none;
  }
  .InputSuggestions {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    z-index: 10000;
    background-color: rgba(165, 237, 194, 0.5);
  }
  .BrowseWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0 20px;
    background-color: #fff;
  }
  .BrowseContainer {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 50px;
    max-width: 1020px;
  }
  td,
  th {
    border: none;
    text-align: left;
    padding: 12px 15px;
    box-shadow: none;
    border-right: 1px solid lightgray;
  }
  .BrowseTable td:last-child,
  .BrowseTable th:last-child {
    border-right: none;
  }
  th {
    font-family: sans-serif;
    font-size: 18px;
  }
  td {
    font-size: 14px;
  }
  th {
    border-bottom: 1px solid lightgray;
  }
  .BrowseTitle {
    font-size: 2rem;
    padding-left: 2rem;
  }
  .BrowseSubtitle {
    font-size: 0.75rem;
    padding-left: 2rem;
  }
  .ChangeUniversityButton {
    margin-left: 2rem;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
  .classCol {
    min-width: 100px;
  }
  .form-control,
  .custom-select {
    width: auto;
    margin-left: 0.5rem;
    display: inline-block !important;
  }
  .dataTables_filter {
    text-align: right;
  }
  .dataTables_paginate {
    margin: 0 auto;
    display: block;
    float: right;
    padding: 0 20px 0 0;
  }
  .dataTables_info {
    text-align: none;
  }
  @media only screen and (max-width: 840px) {
    .BrowseTable {
      font-size: 12px;
    }
    td,
    th {
      padding: 8px 12px;
    }
  }
  @media only screen and (max-width: 768px) {
    .dataTables_filter,
    .bs-select {
      text-align: center;
    }
  
    .dataTables_paginate {
      font-size: 12px;
      margin: 0 auto;
      display: table;
      padding: 0;
      float: none;
    }
  
    .dataTables_info {
      text-align: center;
    }
  }
  @media only screen and (max-width: 720px) {
    .ChooseUniversityTitle {
      font-size: 2rem;
      margin-bottom: 5vh;
    }
  }
  @media only screen and (max-width: 550px) {
    .BrowseTable {
      word-break: break-word;
      letter-spacing: 0.5px;
    }
    td,
    th {
      padding: 8px 5px;
    }
  
    td {
      font-size: 10px;
    }
  
    th {
      font-size: 12px;
    }
  
    .BrowseSubtitle,
    .BrowseTitle {
      padding-left: 1rem;
    }
  
    .ChangeUniversityButton {
      margin-left: 1rem;
    }
  
    .classCol {
      min-width: 70px;
    }
    .tufts_custom_img_style {
      margin-left: 50%;
      transform: translateX(-50%);
    }
    .tufts_custom_col_style {
      margin: 0;
    }
  }
  
  .reserveBtn {
    text-align: right;
    padding-right: 15%;
  }
  
  .reserveBtn .btn {
    width: 25%;
  }
  
  /*.reserveCheckbox{
    text-align: center;
  }*/
  
  .ReservationInstructions {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
  }