.AddToCart {
    display: inline-block;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 100;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    background-color: #6c757d; /* Softer color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .AddToCart:hover {
    background-color: #5a6268; /* Slightly darker for hover */
  }