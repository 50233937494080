hr {
    display: block;
    height: 1px;
    border: 0;
    width: 20%;
    border-top: 2px solid #1a3257;
    margin: 50px auto;
    padding: 0;
  }
  .BodyWrapper {
    max-width: 1170px;
    margin: 0 auto;
  }
  .TitleSection {
    margin-top: 70px;
    height: 600px;
  }
  .SectionWrapper {
    background-color: #fff;
    padding: 50px 20px;
    font-family: 'Archivo', sans-serif;
  }
  .SectionTitle {
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    font-family: 'Merriweather', sans-serif;
    color: #1a3257;
  }
  .SectionSubtitle {
    text-align: center;
    line-height: 1.3;
    color: #1a3257;
    font-size: 24px;
    padding: 30px 0 10px 0;
  }
  .NewFeatureTitle {
    position: relative;
    z-index: 10000;
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .NewFeatureDesc {
    width: 50%;
    margin-bottom: 1.5rem;
  }
  div > .badge {
    vertical-align: middle;
    margin-top: -0.5em;
  }
  .SectionContent {
    text-align: center;
    font-size: 18px;
    margin: auto;
    padding: 20px;
    line-height: 1.5;
    position: relative;
    max-width: 1000px;
    color: #1a3257;
  }
  .StatisticCanvasWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 0;
    color: #1a3257;
  }
  .StatisticCanvasContainer {
    padding: 0 5px;
    color: #1a3257;
  }
  .StatisticCanvas {
    color: #1f78b4;
  }
  .StatisticCanvasLabel {
    text-align: center;
    color: gray;
  }
  .Citation {
    text-align: center;
    padding: 20px 0px;
  }
  .uni_image {
    margin: 20px 0px;
  }
  .slick-initialized .slick-track {
    display: flex;
    align-items: center;
  }
  .SectionTitleHeader {
    font-size: 55px;
    font-weight: 800;
    color: #1a3257;
    position: relative;
    z-index: 10;
  }
  .SectionSubtitleHeader {
    line-height: 1.3;
    color: #1a3257;
    font-size: 30px;
    padding: 10px 0 10px 0;
    position: relative;
    z-index: 10;
  }
  .BookHeaderImage {
    width: 400px;
    height: 109px;
    position: absolute;
    top: 470px;
    right: 175px;
    z-index: 15;
  }
  .btn-outline-primary {
    color: rgba(25, 51, 87, 0.75);
    border: 2px solid rgba(25, 51, 87, 0.75);
  }
  .btn-outline-primary:hover {
    color: #fff;
    border: 2px solid rgba(25, 51, 87, 0.75);
    background-color: rgba(25, 51, 87, 0.75);
  }
  .btn-rounded {
    border-radius: 40px;
    position: relative;
    z-index: 15;
  }
  .left {
    text-align: left;
    margin-left: 50px;
  } 
  .center {
    text-align: center;
  }
  .padded {
    margin-top: 5px;
  }
  .circle {
    border-radius: 50%;
  }
  .blue {
    background-color: #4f8fcc;
    height: 550px;
    width: 550px;
    opacity: 60%;
    position: absolute;
    top: 109px;
    /*left: 170px;*/
    right: 200px;
    z-index: 5;
  }
  .green {
    background-color: #6cc991;
    height: 200px;
    width: 200px;
    opacity: 60%;
    position: absolute;
    top: 181px;
    /*left: 600px;*/
    right: 100px;
    z-index: 5;
  }
  .yellow {
    background-color: #fec66b;
    height: 350px;
    width: 350px;
    opacity: 60%;
    position: absolute;
    top: 270px;
    /*left: 500px;*/
    right: 50px;
    z-index: 5;
  }
  .SlickCarousel {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .CarouselImage {
    margin: 0 auto;
  }
  .DescriptionRow {
    max-width: 1080px !important;
    margin: 50px auto;
    padding: 0 24px;
  }
  
  .SideSubtitle,
  .SideDescription {
    color: #1a3257;
    text-align: left;
  }
  .SideSubtitle {
    font-size: 24px;
    font-weight: 800;
  }
  .SideDescription {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 28px;
    font-size: 16px;
  }
  .ImageWrap {
    height: 100%;
  }
  .DescriptionImages {
    /*height: 433px;*/
    width: 100%;
  }
  .OdometerContainer {
    text-align: center;
    width: 80%;
    margin: auto;
  }
  .odometer {
    font-size: 6rem !important;
  }
  .odometer .odometer-inside:before {
    content: '$';
  }
  .odometer .odometer-inside .odometer-digit:first-child,
  .odometer .odometer-inside .odometer-formatting-mark:nth-child(2) {
    display: none;
  }
  .odometer {
    color: #6cc991;
  }
  .odometer .odometer-inside:before {
    display: inline-block;
    vertical-align: sup;
    opacity: 0.6;
    font-size: 0.85em;
    margin-right: 0.12em;
  }
  .NavPillContainer {
    margin: auto;
    width: 75%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: -30px;
  }
  .NavFullContainer {
    text-align: center;
    max-width: 1080px !important;
    margin: 70px auto;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  a.nav-link {
    color: #4f8fcc !important;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #4f8fcc !important;
  }
  .left-align {
    text-align: left;
}
  .BigNumberYellow,
  .BigNumberGreen {
    position: relative;
    font-size: 300px;
    font-weight: 900;
    text-align: left;
    margin: -50px;
    left: 75px;
    line-height: 300px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 0%;
  }
  .BigNumberYellow {
    color: #fec96b;
  }
  .BigNumberGreen {
    color: #9fdfb3;
  }

  .HowToText {
    color: #1a3157;
    text-align: left;
    position: absolute;
    top: 40%;
    left: 30%;
    width: 65%;
  }
  
  .col-md-4 {
    position: relative; /* Establishes a positioning context for absolute children */
  }
  .EndingWrapper {
    padding: 0px 20px;
    margin-top: 100px;
    text-align: center;
  }
  @media only screen and (max-width: 1100px) {
    .DescriptionRow {
      max-width: 696px !important;
    }
    .DescriptionImages {
      /*height: 272px;*/
      margin: 35px auto;
      width: 100%;
    }
    .NewFeatureDesc {
      width: 35%;
      margin-bottom: 1.5rem;
    }
  }
  
  @media only screen and (max-width: 950px) {
    .blue {
      height: 320px;
      width: 320px;
      right: 150px;
      top: 239px;
    }
    .green {
      height: 100px;
      width: 100px;
      top: 311px;
    }
    .yellow {
      height: 225px;
      width: 225px;
      top: 400px;
    }
    .BookHeaderImage {
      width: 294px;
      height: 80px;
      top: 570px;
      right: 105px;
    }
    .TitleSection {
      margin-top: 70px;
      height: 600px;
    }
    .BigNumber {
      font-size: 250px;
    }
  }
  
  @media only screen and (max-width: 845px) {
    .TitleSection {
      height: 700px;
    }
  }
  
  @media only screen and (max-width: 747px) {
    .odometer {
      font-size: 4rem !important;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .NewFeatureDesc {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  
    .blue {
      height: 320px;
      width: 320px;
      right: 150px;
      top: 239px;
    }
    .green {
      height: 100px;
      width: 100px;
      top: 311px;
    }
    .yellow {
      height: 225px;
      width: 225px;
      top: 400px;
    }
    .BookHeaderImage {
      display: none;
    }
    .TitleSection {
      margin-top: 30px;
      height: 700px;
    }
    .BigNumber {
      font-size: 200px;
    }

    .HowToText {
      left: 40%;
      width: 55%;
    }
  }
  
  @media only screen and (max-width: 520px) {
    .NewFeatureTitle {
      margin-top: 80px;
      width: 75%;
    }
    .blue {
      height: 320px;
      width: 320px;
      right: 50px;
      top: 239px;
    }
    .green {
      height: 100px;
      width: 100px;
      top: 311px;
      right: 25px;
    }
    .yellow {
      height: 225px;
      width: 225px;
      top: 400px;
      right: 10px;
    }
    .TitleSection {
      margin-top: 30px;
      height: 900px;
    }
    .odometer {
      font-size: 2.5rem !important;
    }
    .HowToText {
      top: 35%;
    }
  }
  @keyframes bouncing {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -10px, 0);
    }
  
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -6px, 0);
    }
  
    90% {
      transform: translate3d(0, -2px, 0);
    }
  }
  
  .bouncing {
    animation-duration: 1s;
    animation-name: bouncing;
    animation-delay: 1s;
    animation-iteration-count: 3;
  }