.titleTC {
    text-align: left;
    font-size: 42px;
    font-family: 'Helvetica Neue', 'Merriweather', sans-serif;
    font-weight: bold;
    color: #1a3257;
    padding-top: 20px;
  }
  .terms {
    line-height: 1.5;
    width: 90%;
    white-space: normal;
    margin: auto;
  }
  .lower-latin {
    list-style-type: lower-latin;
  }
  .decimal {
    list-style-type: decimal;
  }
  li {
    display: list-item;
  }
  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  